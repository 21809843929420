import '../style.scss'

import * as THREE from 'three'

import vertexPars from '../shaders/vertex_pars.glsl'
import vertexMain from '../shaders/vertex_main.glsl'
import fragmentPars from '../shaders/fragment_pars.glsl'
import fragmentMain from '../shaders/fragment_main.glsl'

// others js
import { environmentMap, isAllReady } from './loader'



let powerX = 0.1

// RAYCASTER
const pointer = new THREE.Vector2();

function onPointerMove(event) {
    pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
    pointer.y = - (event.clientY / window.innerHeight) * 2 + 1;
}
window.addEventListener('pointermove', onPointerMove);

/**
 * Base
 */

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

// #region lights

// Ambient light
const ambientLight = new THREE.AmbientLight('#000000', 1)
// Directional light
const directionalLight = new THREE.DirectionalLight('#000000', 1)
directionalLight.position.set(2, 2, 2)
// Point Light
const pointLight = new THREE.PointLight('#2f367a', 5)
pointLight.position.set(2, 3, 4)

const pointLight2 = new THREE.PointLight(0xfff0ff, 5)
pointLight2.position.set(-2, -3, 2)

const pointLight3 = new THREE.PointLight('#5baf4f', 5)
pointLight3.position.set(-2, -3, 2)

scene.add(ambientLight, directionalLight)

// #endregion


/**
 * Textures
 */

environmentMap.encoding = THREE.sRGBEncoding
scene.environment = environmentMap

// Geometry
const geometry = new THREE.IcosahedronGeometry(1, 100)

// Material
let coef = 10.0;
let coefUTime = 1.5;

const material = new THREE.MeshStandardMaterial({
    onBeforeCompile: (shader) => {
        // storing a reference to the shader object
        material.userData.shader = shader;

        // uniforms
        shader.uniforms.uTime = { value: 0 };
        shader.uniforms.mouseX = { value: 0 };
        shader.uniforms.mouseY = { value: 0 };
        shader.uniforms.coef = { value: coef };
        shader.uniforms.coefUTime = { value: coefUTime };

        const parsVertexString = /* glsl */`#include <displacementmap_pars_vertex>`
        shader.vertexShader = shader.vertexShader.replace(parsVertexString, parsVertexString + '\n' + vertexPars)

        const mainVertexString = /* glsl */`#include <displacementmap_vertex>`
        shader.vertexShader = shader.vertexShader.replace(mainVertexString, mainVertexString + '\n' + vertexMain)

        const parsFragmentString = /* glsl */`#include <bumpmap_pars_fragment>`
        shader.fragmentShader = shader.fragmentShader.replace(parsFragmentString, parsFragmentString + '\n' + fragmentPars)

        const mainFragmentString = /* glsl */`#include <normal_fragment_maps>`
        shader.fragmentShader = shader.fragmentShader.replace(mainFragmentString, mainFragmentString + '\n' + fragmentMain)

        const colorFragmentString = /* glsl */`#include <color_fragment>`
        shader.fragmentShader = shader.fragmentShader.replace(colorFragmentString, colorFragmentString + '\n' + `diffuseColor = vec4(vec3(vDisplacement * 1.0), 1.0);`)


    },
})

let metalness = .5;
let roughness = 0.3;

material.metalness = metalness;
material.roughness = roughness;

// Mesh
const mesh = new THREE.Mesh(geometry, material)
scene.add(mesh)

/**
 * Sizes
 */
const sizes = {
    width: 300,
    height: 300
}

window.addEventListener('resize', () => {
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.OrthographicCamera(sizes.width / -200, sizes.width / 200, sizes.height / 200, sizes.height / -200, 1, 1000)
camera.position.set(0, 0, 6)
scene.add(camera)

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true,
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))


let xMouse = 0;
let yMouse = 0;
// MOUSE MOVE
document.addEventListener('mousemove', (event) => {
    xMouse = event.clientX - window.innerWidth / 2;  // Horizontal
    yMouse = event.clientY - window.innerHeight / 2;  // Vertical
    coefUTime = Math.max(Math.abs(event.movementX / 10), 1)
    coef = Math.sqrt(Math.pow(xMouse, 2) + Math.pow(yMouse, 2)) / 400 + 1.0;
    coef = Math.min(coef, 2.2)
})

function lerp(start, end, amt) {
    return (1 - amt) * start + amt * end
}

let body = document.querySelector('body');
body.style.background = 'rgb(12, 12, 12)';


/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0

let spdx = 0;
let spdy = 0;

const tick = () => {

    const elapsedTime = clock.getElapsedTime()
    previousTime = elapsedTime

    //encoding
    renderer.outputEncoding = THREE.sRGBEncoding;

    // Update material
    if (material.userData.shader?.uniforms) {
        material.userData.shader.uniforms.uTime.value = elapsedTime;
        material.userData.shader.uniforms.mouseX.value += spdx;
        material.userData.shader.uniforms.mouseY.value += spdy;
        material.userData.shader.uniforms.coef.value = lerp(material.userData.shader.uniforms.coef.value, coef, 0.05);
    }
    material.metalness = lerp(material.metalness, metalness, 0.05);
    material.roughness = lerp(material.roughness, roughness, 0.05);

    renderer.render(scene, camera)
    powerX = lerp(powerX, 0, 0.1)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}
window.onload = (event) => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) return
    tick()
    coef = 2.0
    xMouse = 0;
    yMouse = 0;
};


window.addEventListener('beforeunload', function (event) {
    canvas.style.display = 'none';
});