
vec3 coords = normal;
// coords.x += uTime / 10.0;
// coords.y += uTime / 10.0;
// coords.z += uTime / 10.0;
// vec3 noisePattern = vec3(noise(coords * coef + uTime / 5.0) / 40.0);
// if (coef == 1.0) {
// vec3 noisePattern = vec3((noise(coords*sin(coords)-coords*cos(coords) + uTime * coefUTime / 5.0))) * coef / 40.0 * coef;
vec3 noisePattern = smoothstep(-0.3,2.0,vec3((noise(coords*sin(coords)-coords*cos(coords) + uTime * coefUTime / 5.0)))/ 40.0 * pow(coef + 1.0,2.0));
// } else {
// vec3 noisePattern = vec3(noise(coords * 5.0 + uTime / 1.0) / 10.0);
// }
// vec3 noisePattern = vec3(((coords.x*mouseX + coords.y*mouseY) * sqrt(abs(mouseX*mouseX) + abs(mouseY*mouseY)) + 0.02) * 1.0 * coords.x + uTime / 5.0);
// vec3 noisePattern = vec3(((coords.x*mouseX*5.0 + coords.y*mouseY*5.0) * (sqrt(abs(mouseX*mouseX) + abs(mouseY*mouseY))) / 100.0 + 0.05) * 1.0 * noise(coords + uTime / 5.0));
// vec3 noisePattern = smoothstep(0.0,10.0,vec3(max(((coords.x*mouseX + coords.y*mouseY) * sqrt(abs(mouseX*mouseX) + abs(mouseY*mouseY)) + 0.02), 0.01) * 1.0 * noise(coords + uTime / 10.0)));
float pattern = wave((noisePattern) / 2.0) - 0.5;

// pattern 2
// float pattern = wave(vec3(1.0, cos(coords.x + uTime / 10.0)*cos(coords.z + uTime / 10.0), 1.0));
// pattern 3
// float pattern = cos(coords.y*mouseX);

// varyings
vDisplacement = pattern;

float displacement = vDisplacement / 3.0;

transformed += normalize(objectNormal) * displacement;