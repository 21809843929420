import * as THREE from 'three'

const loadingBar = document.querySelector('#loading-bar')
const loadingBarContainer = document.querySelector('#loading-bar_container')
const loader = document.querySelector('#loader')
const webgl = document.querySelector('#webgl')
let totalItemsLoaded = 0
let progressRatio = []
let isAllReady = false

const loadingManager = new THREE.LoadingManager(
    // Loaded
    () => {
        if (loader) {
            setTimeout(() => {
                setTimeout(() => {
                    loader.classList.add('hidden')
                    isAllReady = true
                }, 500);
                setTimeout(() => {
                    webgl.classList.add('active')
                }, 1000);
            }, 1000 + totalItemsLoaded * 50)
        }
    },
    // Progress
    (itemsUrl, itemsLoaded, itemsTotal) => {
        if (loader) {
            loadingBarContainer.classList.remove('hidden')
            setTimeout(() => {
                loadingBar.style.transform = `scaleX(${itemsLoaded / itemsTotal})`
                loadingBar.style.transition = 'transform 0.5s, opacity 1s'
            }, 1000)
            progressRatio.push(itemsLoaded / itemsTotal)
            totalItemsLoaded = itemsTotal
        }
    }
)

const cubeTextureLoader = new THREE.CubeTextureLoader(loadingManager)

/**
 * Environment map
 */
const environmentMap = cubeTextureLoader.load([
    '/textures/envmap/px.jpg',
    '/textures/envmap/nx.jpg',
    '/textures/envmap/py.jpg',
    '/textures/envmap/ny.jpg',
    '/textures/envmap/pz.jpg',
    '/textures/envmap/nz.jpg'
])

export { loadingManager, cubeTextureLoader, environmentMap, isAllReady }